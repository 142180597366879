.center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.italics {
  font-style: italic !important;
}

.strong {
  font-weight: bold !important;
}

.caption {
  text-transform: uppercase;
}

.display-block {
  display: block !important;
}

.height-100 {
  height: 100vh !important;
}

.height-min-100 {
  min-height: 100% !important;
}

.width-100 {
  width: 100vw !important;
}

.width-min-100 {
  min-width: 100% !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
.game-gallery {
  width: 98.2%;
  padding: 64px;
}

.gallery-heading {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  color: #00887a;
}

.game-slide {
  position: relative;
  text-align: center;
  padding: 32px;
  width: 100%;
}

.game-card {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 90%;
  margin: 0 auto;
}

.game-card:hover {
  transform: scale(1.05);
}

.game-thumbnail {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.game-info {
  margin-top: 15px;
  text-align: center;
}

.game-info h3 {
  margin-bottom: 5px;
  font-size: 18px;
  color: #333;
}

.game-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.game-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.play-button {
  background-color: #00887a;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.play-button:hover {
  background-color: #00695c;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.custom-modal {
  position: relative;
  width: 90%;
  height: 90vh;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  animation: scaleIn 0.3s ease;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff6b6b;
}

/* Adjust carousel for vertical mode on mobile */
@media (max-width: 768px) {
  /* .slick-slider {
    max-height: none;
  } */
  .gallery-heading {
    text-align: left;
    font-size: 24px;
    color: #00887a;
  }

  .game-gallery {
    width: 100%;
    padding: 0px;
  }

  /* .slick-track {
    display: block !important;
  }

  .slick-slide {
    display: inline-block;
    width: 100% !important;
    height: auto !important;
  } */
  /* .slick-prev:before,
  .slick-next:before {
    color: transparent;
  } */

  .game-card {
    width: 100%;
  }
}

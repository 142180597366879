.margin--top {
  margin-top: 16px !important;
}

.margin--right {
  margin-right: 16px !important;
}

.margin--left {
  margin-left: 16px !important;
}

.margin--bottom {
  margin-bottom: 16px !important;
}

.margin--sides {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.margin--ends {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.margin-half--top {
  margin-top: 8px !important;
}

.margin-half--right {
  margin-right: 8px !important;
}

.margin-half--left {
  margin-left: 8px !important;
}

.margin-half--bottom {
  margin-bottom: 8px !important;
}

.margin-half--sides {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.margin-half--ends {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.margin-double--top {
  margin-top: 32px !important;
}

.margin-double--right {
  margin-right: 32px !important;
}

.margin-double--left {
  margin-left: 32px !important;
}

.margin-double--bottom {
  margin-bottom: 32px !important;
}

.margin-double--sides {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.margin-double--ends {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}


.margin-quarter--top {
  margin-top: 4px !important;
}

.margin-quarter--right {
  margin-right: 4px !important;
}

.margin-quarter--left {
  margin-left: 4px !important;
}

.margin-quarter--bottom {
  margin-bottom: 4px !important;
}

.margin-quarter--sides {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.margin-quarter--ends {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.margin-one-half--top {
  margin-top: 24px !important;
}

.margin-one-half--right {
  margin-right: 24px !important;
}

.margin-one-half--left {
  margin-left: 24px !important;
}

.margin-one-half--bottom {
  margin-bottom: 24px !important;
}

.margin-one-half--sides {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.margin-one-half--ends {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}


.no-margin--top {
  margin-top: 0px !important;
}

.no-margin--right {
  margin-right: 0px !important;
}

.no-margin--left {
  margin-left: 0px !important;
}

.no-margin--bottom {
  margin-bottom: 0px !important;
}

.no-margin--sides {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.no-margin--ends {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
